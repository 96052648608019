import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import HiddenOnDesktop from "../components/HiddenOnDesktop"
import HiddenOnMobile from "../components/HiddenOnMobile"
import HomepageImageButtons from "../components/HomepageImageButtons"
import { Language } from "../components/i18n"
import { LatestPostsEn, LatestPostsPl } from "../components/LatestPosts"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import useCommonStyles from "../library/useCommonStyles"
import { IndexPageQuery } from "../types/schema"

type Props = {
  data: IndexPageQuery
  language: Language
}

const useStyles = makeStyles((theme) => ({
  centeredAbsolute: {
    height: "50%",
    position: "absolute",
    right: "25%",
    textAlign: "center",
    top: "25%",
    width: "50%",
  },
  centeredFlex: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  headerDesktopContainer: {
    bottom: "20%",
    height: "50%",
    left: "10%",
    position: "absolute",
    textAlign: "center",
    width: "30%",
  },
  headerDesktopText: {
    "@media (min-width: 700px)": {
      fontSize: "175px",
    },
    "@media (min-width: 800px)": {
      fontSize: "200px",
    },
    color: theme.palette.common.white,
    fontSize: "150px",
  },
  headerMobileText: {
    color: theme.palette.primary.main,
    fontSize: "100px",
    lineHeight: "100px",
  },
}))

export const fragment = graphql`
  fragment IndexPageMainDesktopPhoto on WpMediaItem {
    localFile {
      childImageSharp {
        fluid(grayscale: true, maxHeight: 600, maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  fragment IndexPageMainMobilePhoto on WpMediaItem {
    localFile {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function IndexPage({ data, language }: Props) {
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  return (
    <Layout>
      <Seo />
      <div className={commonClasses.relative}>
        <HiddenOnDesktop>
          <Img
            fluid={
              data.wpOptions.homepagephotos.headerPhotoMobile.localFile
                .childImageSharp.fluid
            }
          />
          <div className={classes.centeredAbsolute}>
            <div className={classes.centeredFlex}>
              <Typography
                className={classes.headerMobileText}
                component="span"
                variant="h2"
              >
                {data.wpOptions.homepagephotos.headerPhotoMobileText}
              </Typography>
            </div>
          </div>
        </HiddenOnDesktop>
        <HiddenOnMobile>
          <Img
            fluid={
              data.wpOptions.homepagephotos.headerPhoto.localFile
                .childImageSharp.fluid
            }
          />
          <div className={classes.headerDesktopContainer}>
            <Typography
              className={classes.headerDesktopText}
              component="span"
              variant="h2"
            >
              {data.wpOptions.homepagephotos.headerPhotoText}
            </Typography>
          </div>
        </HiddenOnMobile>
      </div>
      {language === "pl" ? <LatestPostsPl /> : <LatestPostsEn />}
      {data.wpPage && (
        <Container className={commonClasses.pageContainer}>
          <Typography
            color="primary"
            component="h2"
            dangerouslySetInnerHTML={{
              __html: data.wpPage.title,
            }}
            variant="h2"
          />
          <Typography
            className={commonClasses.threeFours}
            color="secondary"
            component="h2"
            dangerouslySetInnerHTML={{
              __html: data.wpPage.content,
            }}
          />
        </Container>
      )}
      <HomepageImageButtons data={data} />
    </Layout>
  )
}
