import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import useCommonStyles from "../library/useCommonStyles"
import { IndexPageQuery } from "../types/schema"

import { Link } from "./i18n"

const useStyles = makeStyles((theme) => ({
  imageButtonContainer: {
    marginBottom: "25px",
  },
  imageButtonItem: {
    "@media (min-width: 600px)": {
      minWidth: "200px",
    },
    flex: 1,
    margin: 10,
    minWidth: "330px",
  },
  textOverImageContainer: {
    bottom: "0px",
    left: "20px",
    position: "absolute",
  },
  textOverImageText: {
    color: theme.palette.common.white,
    fontSize: "120px",
    lineHeight: 1,
  },
}))

function TextOverImage({ text }: { text: string }) {
  const classes = useStyles()

  return (
    <div className={classes.textOverImageContainer}>
      <Typography className={classes.textOverImageText} variant="h3">
        {text}
      </Typography>
    </div>
  )
}

type Props = {
  data: IndexPageQuery
}

export const fragment = graphql`
  fragment HomepageImageButtonPhoto on WpMediaItem {
    localFile {
      childImageSharp {
        fluid(grayscale: true, maxHeight: 427, maxWidth: 427) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function HomepageImageButtons({ data }: Props) {
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  return (
    <Grid className={classes.imageButtonContainer} container>
      <Grid className={classes.imageButtonItem} item>
        <Link to="/about-us">
          <div className={commonClasses.relative}>
            <Img
              fluid={
                data.wpOptions.homepagephotos.photoButtonAboutUs.localFile
                  .childImageSharp.fluid
              }
            />
            <TextOverImage
              text={data.wpOptions.homepagephotos.photoButtonAboutUsText}
            />
          </div>
        </Link>
      </Grid>
      <Grid className={classes.imageButtonItem} item>
        <Link to="/team">
          <div className={commonClasses.relative}>
            <Img
              fluid={
                data.wpOptions.homepagephotos.photoButtonTeam.localFile
                  .childImageSharp.fluid
              }
            />
            <TextOverImage
              text={data.wpOptions.homepagephotos.photoButtonTeamText}
            />
          </div>
        </Link>
      </Grid>
      <Grid className={classes.imageButtonItem} item>
        <Link to="/classes">
          <div className={commonClasses.relative}>
            <Img
              fluid={
                data.wpOptions.homepagephotos.photoButtonClasses.localFile
                  .childImageSharp.fluid
              }
            />
            <TextOverImage
              text={data.wpOptions.homepagephotos.photoButtonClassesText}
            />
          </div>
        </Link>
      </Grid>
    </Grid>
  )
}
